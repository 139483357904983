<template>
	<div class="wrapper content-view">
		<!-- <notifications></notifications> -->
		<side-bar>
			<template v-slot:links>
				<!-- 				<sidebar-item
					:link="{
						name: 'Dashboards',
						icon: 'ni ni-shop text-primary',
					}"
				>
					<sidebar-item
						:link="{ name: 'Dashboard', path: '/dashboard' }"
					></sidebar-item>
					<sidebar-item
						:link="{ name: 'Alternative', path: '/alternative' }"
					></sidebar-item>
				</sidebar-item> -->

				<sidebar-item
					v-if="showOmnichannel"
					:link="{
						name: 'Omnichannel',
						icon: 'fas fa-sms text-blue',
					}"
				>
					<sidebar-item
						@click="unsetRoom"
						:link="countAssignedTickets"
					></sidebar-item>
					<sidebar-item
						@click="unsetRoom"
						:link="countUnassignedTickets"
					></sidebar-item>
					<sidebar-item
						v-if="showBot"
						@click="unsetRoom"
						:link="countBotTickets"
					></sidebar-item>
					<!-- <sidebar-item
						:link="{
							name: 'All',
							path: '/omnichannel/messages/all',
							icon: 'fas fa-list',
						}"
					></sidebar-item> -->
				</sidebar-item>
				<sidebar-item
					v-if="isCallCenter"
					:link="{
						name: 'CallCenter',
						icon: 'fas fa-phone-square text-pink',
					}"
				>
					<sidebar-item
						:link="{
							name: 'Incoming',
							path: '/callcenter/incoming',
							icon: 'fas fa-phone-volume',
						}"
					/>
					<sidebar-item
						:link="{
							name: 'Call History',
							path: '/callcenter/history',
							icon: 'fas fa-archive',
						}"
					/>
				</sidebar-item>

				<sidebar-item
					v-if="showOmnichannel"
					:link="{
						name: 'Widgets',
						icon: 'ni ni-ui-04 text-info',
					}"
				>
					<sidebar-item
						:link="{ name: 'Notificaciones', path: '/widgets/notifications' }"
					/>
				</sidebar-item>
				<sidebar-item
					:link="{
						name: 'Mi perfil',
						icon: 'fas fa-user-circle text-danger',
						path: '/user',
					}"
				>
				</sidebar-item>
			</template>

			<template v-slot:links-after>
				<hr class="my-3" />
				<!-- <h6 class="navbar-heading p-0 text-muted">Información</h6>

				<ul class="navbar-nav mb-md-3">
					<li class="nav-item">
						<a
							class="nav-link"
							href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation"
							target="_blank"
						>
							<i class="fas fa-file-pdf"></i>
							<span class="nav-link-text">Manual</span>
						</a>
					</li>
				</ul> -->
			</template>
		</side-bar>
		<div class="main-content">
			<dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
			<transition
				enter-active-class="miniFadeInUp"
				leave-active-class="miniFadeOutUp"
			>
				<notifications v-if="showNotification" />
				<sesion-modal v-else-if="showInactiveModal" />
			</transition>
			<div @click="$sidebar.displaySidebar(false)">
				<router-view></router-view>
			</div>
			<content-footer v-if="!$route.meta.hideFooter"></content-footer>
		</div>

	</div>

</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
	return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
	if (hasElement(className)) {
		new PerfectScrollbar(`.${className}`);
	} else {
		// try to init it later in case this component is loaded async
		setTimeout(() => {
			initScrollbar(className);
		}, 100);
	}
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import Notifications from "../Components/Notifications.vue";
import SesionModal from "../Components/SesionModal.vue";
import { AuthModule } from "@/store/modules/Auth";
import { AuthStatus } from "@/types/router";
import { TicketModule } from "@/store/modules/Ticket";
import { env } from "@/config/index";
import { NotificationModule } from "@/store/modules/Notification";

export default {
	components: {
		DashboardNavbar,
		ContentFooter,
		Notifications,
		SesionModal,
	},
	data() {
		return {
			ticketID: "",
			showBot: env.Bot,
		};
	},
	methods: {
		initScrollbar() {
			let isWindows = navigator.platform.startsWith("Win");
			if (isWindows) {
				initScrollbar("sidenav");
			}
		},
		unsetRoom() {
			TicketModule.unsetRoom();
		},
	},
	mounted() {
		this.initScrollbar();
	},
	computed: {
		isCallCenter: {
			get() {
				return (
					env.requiredCallcenter &&
					AuthModule.status === AuthStatus.FULL_AUTHENTICATED
				);
			},
		},
		showOmnichannel: {
			get() {
				return !env.onlyCallcenter;
			},
		},
		showNotification() {
			return NotificationModule.showing;
		},
		showInactiveModal() {
			return AuthModule.inactive;
		},
		countAssignedTickets() {
			const count = TicketModule.allTickets.claimed.filter(
				t => t.status === "claimed"
			).length;

			return {
				name: `Asignados (${count})`,
				path: "/omnichannel/messages/assigned",
				icon: "ni ni-circle-08",
			};
		},
		countBotTickets() {
			const count = TicketModule.allTickets.bot.length;

			return {
				name: `Asistente Virtual (${count})`,
				path: "/omnichannel/messages/bot",
				icon: "fas fa-robot",
			};
		},
		countUnassignedTickets() {
			const count = TicketModule.allTickets.open.filter(
				t => t.status === "open"
			).length;

			return {
				name: `Sin asignar (${count})`,
				path: "/omnichannel/messages/unassigned",
				icon: "far fa-question-circle",
			};
		},
	},
};
</script>
<style lang="scss"></style>
