import {
	Module,
	Mutation,
	Action,
	VuexModule,
	getModule,
} from "vuex-module-decorators";
import { IRootState, store } from "@/store";
import { Module as Mod } from "vuex";
import { AuthModule } from "./Auth";
import { AuthStatus } from "@/types/router";
import { AxiosResponse } from "axios";
import {
	callCenterClient,
	coreApi,
	omniChannelClient,
	simpleCallCenterClient,
} from "@/conection/index";
import {
	IUser,
	PartialUser,
	ITypification,
	IEvaluation,
	IChannel,
	ISaveCall,
} from "@/callcenter/omni";
import { alertMessages } from "@/util/consts";
import { AlertModule } from "@/store/modules/Alert";
import { fullName } from "@/util/parse";
import { HistoryModule } from "./History";
import { capitalizeFirst, validateTipification } from "@/util/utils";
import router from "@/router";
import { apiPetitions, removeExitDigits } from "@/util/utils";
import { env } from "@/config";
import { ISaveCallType, ICallData, IActiveCall } from "@/types/call";
import { TypificationModalModule } from "./TypificationModal";
import { IndexType } from "@/types/store";
import { parseResponse } from "@/util/data";
import { TicketModule } from "./Ticket";
import { ITicket } from "@/types/tickets";

@Module({ dynamic: true, namespaced: true, store, name: "calldata" })
export class CallData extends VuexModule implements ICallData {
	constructor(module: Mod<ThisType<ICallData>, IRootState>) {
		super(module);
	}

	Scoring = false;
	Fetched = false;
	IsNewUser = false;
	ActiveCall = {
		call_number: "",
		uniqueid: "",
		call_status: "Cola",
		resultado: 0,
		call_type: "",
		queue: "",
	};
	LoadingSK = false;
	LoadingCallRating = false;
	LoadingCallDelete = false;

	User: PartialUser | undefined;
	Typification: ITypification[] | undefined;
	TypificationFetched = false;
	Channel: IChannel[] | undefined;
	ChannelsFetched = false;
	ErrorAlert = false;
	saveCallType = ISaveCallType.single;
	enableControls = true;
	callDataCount = 0;
	queueCalls = {};
	get scoring() {
		return this.Scoring;
	}

	get fetched() {
		return this.Fetched;
	}
	get activeCall() {
		return this.ActiveCall;
	}
	get user() {
		return this.User;
	}
	get isNewUser() {
		return this.IsNewUser;
	}
	get loadingSK() {
		return this.LoadingSK;
	}
	get getLoadingCallRating() {
		return this.LoadingCallRating;
	}
	get getLoadingCallDelete() {
		return this.LoadingCallDelete;
	}
	get Typifications() {
		return this.Typification;
	}
	get Channels() {
		return this.Channel;
	}
	get fetchedTypification() {
		return this.TypificationFetched;
	}
	get errorAlert() {
		return this.ErrorAlert;
	}
	get getSaveCallType() {
		return this.saveCallType;
	}
	get getenableControls() {
		return this.enableControls;
	}
	get getCallDataCount() {
		return this.callDataCount;
	}
	get getQueueCalls() {
		return this.queueCalls;
	}
	@Mutation
	setErrorAlert(error: boolean) {
		this.ErrorAlert = error;
	}
	@Mutation
	setFetChed(fetched: boolean) {
		this.Fetched = fetched;
	}
	@Mutation
	setActiveCall(ActiveCall: IActiveCall) {
		this.ActiveCall.call_number = ActiveCall.call_number;
		this.ActiveCall.call_status = ActiveCall.call_status;
		this.ActiveCall.call_type = ActiveCall.call_type;
		this.ActiveCall.queue = ActiveCall.queue;
		this.ActiveCall.resultado = ActiveCall.resultado;
		this.ActiveCall.uniqueid = ActiveCall.uniqueid;
	}

	@Mutation
	setScore(Scoring: boolean) {
		this.Scoring = Scoring;
	}
	@Mutation
	setUser(user: PartialUser | undefined) {
		this.User = user;
	}
	@Mutation
	setIsNewUser(newUser: boolean) {
		this.IsNewUser = newUser;
	}
	@Mutation
	setLoadingSK(loadingSK: boolean) {
		this.LoadingSK = loadingSK;
	}
	@Mutation
	setLoadingCallRating(loading: boolean) {
		this.LoadingCallRating = loading;
	}
	@Mutation
	setLoadingCallDelete(loading: boolean) {
		this.LoadingCallDelete = loading;
	}
	@Mutation
	setTypification(Typification: ITypification[] | undefined) {
		this.Typification = Typification;
	}
	@Mutation
	setTypificationFetched(TypificationFetched: boolean) {
		this.TypificationFetched = TypificationFetched;
	}
	@Mutation
	setChannels(Channel: IChannel[] | undefined) {
		this.Channel = Channel;
	}
	@Mutation
	setChannelsFetched(ChannelsFetched: boolean) {
		this.ChannelsFetched = ChannelsFetched;
	}
	@Mutation
	setSaveCallType(type: ISaveCallType) {
		this.saveCallType = type;
	}
	@Mutation
	setEnableControls(value: boolean) {
		if (env.ValidateTypificationControls) {
			this.enableControls = value;
		}
	}
	@Mutation
	SOCKET_NEW_CALL({ update }: { update: boolean }) {
		if (update) this.callDataCount = this.callDataCount + 1;
		store.dispatch("calldata/updateActiveCall");
	}
	@Mutation
	setCallsInQueue(payload: object) {
		this.queueCalls = payload;
	}
	@Mutation
	SOCKET_CALLS_IN_QUEUE({ data: information }: { data: any }) {
		this.queueCalls = { ...this.queueCalls, ...information };
	}
	@Action
	async updateActiveCall(
		payload:
			| {
					phone?: string;
					force?: boolean;
			  }
			| undefined = undefined
	) {
		if (AuthModule.status == AuthStatus.FULL_AUTHENTICATED) {
			try {
				this.setErrorAlert(false);
				callCenterClient({
					method: "post",
					url: payload?.force
						? "controllers/GetAgentActiveCall.php?force=true"
						: "controllers/GetAgentActiveCall.php",
					data: new URLSearchParams({
						extension: AuthModule.extension ?? "",
						agente: AuthModule.code ?? "",
					}),
				})
					.then(async (response: AxiosResponse) => {
						if (
							response.data.request_status === "OK" &&
							response.data.call_number != "" &&
							!this.scoring
						) {
							this.setEnableControls(false);
							this.loadTypification();
							this.loadChannels();
							this.setLoadingSK(true);
							const call: IActiveCall = response.data;
							call.call_number = payload?.phone?.length
								? payload.phone
								: call.call_number;
							call.call_number = removeExitDigits(call.call_number);
							call.call_type = capitalizeFirst(call.call_type);
							call.queue = capitalizeFirst(call.queue ?? "");
							this.setActiveCall(call);
							this.setScore(true);
							this.setIsNewUser(true);

							//VERIFICAR SI HAY REGISTRO EL USUARIO EN OMNICANAL
							const OmniResponse: AxiosResponse = await omniChannelClient({
								method: "get",
								url: env.DynamicUser
									? `client/ba/dynamicByNumber/503${call.call_number}`
									: `client/ba/byNumber/503${call.call_number}`,
							});
							if (OmniResponse.status == 200) {
								if (
									OmniResponse.data !== null &&
									OmniResponse.data.data !== null
								) {
									this.setIsNewUser(false);
									const {
										email,
										address,
										company_name,
										customer,
										_id,
										dui_nit,
										dynamicData,
										identification,
										channels,
									} = OmniResponse.data.data as IUser;
									delete customer["name"];
									console.log(customer, "customer enviado");
									this.setUser({
										email,
										address,
										company_name,
										customer,
										_id,
										dui_nit,
										dynamicData,
										identification,
										channels,
									});
								} else {
									const user = {
										customer: {
											first_name: "",
											second_name: "",
											third_name: "",
											last_name: "",
											last_name2: "",
										},
										email: "",
										address: "",
										company_name: "",
									};
									await this.createUser(user);
									await this.updateActiveCall();
									// this.setUser(undefined);
								}
							}
							this.setLoadingSK(false);
						} else if (response.data.request_status === "Error") {
							if (response.data.error_call_code === "790") {
								AuthModule.logoutCallCenter();
								AuthModule.setStatus(AuthStatus.CHAT_AUTHENTICATED);
								AuthModule.setExtension(undefined);
								localStorage.removeItem("CALL_EXTENSION");
								AlertModule.SOCKET_PUSH_NOTIFICATION({
									text: alertMessages.ERROR_SESSION,
									type: "error",
								});
								router.push({ name: "Login" });
							} else {
								AlertModule.SOCKET_PUSH_NOTIFICATION({
									text: response.data.request_message,
									type: "error",
								});
							}
						} else {
							this.setEnableControls(true);
						}
					})
					.catch(error => {
						this.setErrorAlert(true);
						coreApi.post("/api/log", {
							error,
						});
						/* 						if (this.errorAlert) {
							this.setErrorAlert(false);
							AuthModule.setStatus(AuthStatus.CHAT_AUTHENTICATED);
							AlertModule.SOCKET_PUSH_NOTIFICATION({
								text: alertMessages.ERROR_CONECTION,
								type: "error",
							});
							router.push({ name: "Login" });
						} */
					});
			} catch (error) {
				console.error(error, "error");
			}
		}
	}
	@Action
	async newCall(params: any) {
		console.group("*** prueba ***");
		console.log(params);
		console.groupEnd();
	}
	@Action
	async loadTypification() {
		if (this.TypificationFetched == false) {
			try {
				const OmniResponse: AxiosResponse = await omniChannelClient({
					method: "get",
					url: `tipification/ba/find`,
				});
				if (OmniResponse.status == 200) {
					if (OmniResponse.data !== null && OmniResponse.data.data !== null) {
						this.setTypification(OmniResponse.data.data);
						this.setTypificationFetched(true);
					} else {
						this.setTypification(undefined);
						this.setTypificationFetched(false);
					}
				}
			} catch (error) {
				console.error(error, "error");
			}
		}
	}
	@Action
	async loadChannels() {
		if (this.ChannelsFetched == false) {
			try {
				const OmniResponse: AxiosResponse = await omniChannelClient({
					method: "get",
					url: `channel/ba/find_whatsapp`,
				});
				if (OmniResponse.status == 200) {
					if (OmniResponse.data !== null && OmniResponse.data.data !== null) {
						this.setChannels(OmniResponse.data.data);
						this.setChannelsFetched(true);
					} else {
						this.setChannels(undefined);
						this.setChannelsFetched(false);
					}
				}
			} catch (error) {
				console.error(error, "error");
			}
		}
	}
	@Action
	async updateDynamicUser(Partial: IndexType<string | number | object>) {
		try {
			const OmniResponse: AxiosResponse<{
				value: boolean;
				message: string;
				client: PartialUser;
			}> = await omniChannelClient({
				method: "post",
				url: `client/ba/update_dynamic_profile/${
					TicketModule.currentTicket?.client[0]?._id ?? this.user?._id
				}`,
				data: Partial,
			});
			if (OmniResponse.status == 200) {
				if (TicketModule.currentTicket?.client[0]) {
					const updatedTicket: ITicket = TicketModule.currentTicket;
					updatedTicket.client[0] = OmniResponse.data.client as any;
					TicketModule.upsert(updatedTicket);
				} else {
					this.setUser(OmniResponse.data.client);
				}
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: `Cliente actualizado con éxito!`,
					type: OmniResponse.status < 400 ? "success" : "error",
				});
			} else {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: alertMessages.ERROR_UPDATE,
					type: "error",
				});
			}
		} catch (error) {
			console.error(error, "Update user error");
		}
	}
	@Action
	async updateUser(Partial: PartialUser) {
		try {
			const OmniResponse: AxiosResponse = await omniChannelClient({
				method: "post",
				url: `client/ba/update_profile/${this.user?._id}`,
				data: Partial,
			});
			if (OmniResponse.status == 200) {
				if (this.user && Partial.customer) {
					Partial.customer.full_name = fullName(Partial.customer);
					Partial._id = this.user._id;
					const updatedUser = Object.assign({}, this.user, Partial);
					this.setUser(updatedUser);
				}

				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: OmniResponse.data.message,
					type: OmniResponse.data.value ? "success" : "error",
				});
			} else {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: alertMessages.ERROR_UPDATE,
					type: "error",
				});
			}
		} catch (error) {
			console.error(error, "Update user error");
		}
	}
	@Action
	async createUser(Partial: PartialUser) {
		Partial.phones = [this.ActiveCall?.call_number];
		Partial.channel = {
			serviceId: `503${this.ActiveCall?.call_number}@c.us`,
		};
		try {
			const OmniResponse: AxiosResponse = await omniChannelClient({
				method: "post",
				url: env.DynamicUser
					? `client/ba/create_dynamic_profile`
					: `client/ba/create_profile`,
				data: Partial,
			});
			if (OmniResponse.status == 200) {
				const { email, address, company_name, customer, _id, dynamicData } =
					OmniResponse.data.client as IUser;
				delete customer["name"];
				this.setUser({
					email,
					address,
					company_name,
					customer,
					_id,
					dynamicData,
				});
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: OmniResponse.data.message,
					type: OmniResponse.data.value ? "success" : "error",
				});
				this.setIsNewUser(false);
			} else {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: alertMessages.ERROR_UPDATE,
					type: "error",
				});
			}
		} catch (error) {
			console.error(error, "Update user error");
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: alertMessages.ERROR_UPDATE,
				type: "error",
			});
		}
	}
	@Action
	async sendEvaluation(Payload: any): Promise<{ success: boolean }> {
		try {
			this.setLoadingCallRating(true);
			if (!env.DynamicTypification)
				validateTipification(
					Payload?.Evaluation?.tipificacion,
					Payload?.Evaluation?.tipificacion
				);

			if (this.isNewUser) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: alertMessages.ERROR_CALLUSER,
					type: "error",
				});
				return { success: false };
			}
			Payload.queue = this.ActiveCall?.queue;

			const mysqlResponse = await this.sendMysqlEvaluation(Payload);
			console.log("Respuesta desde mysql: ", mysqlResponse);
			if (mysqlResponse == undefined) {
				this.setLoadingCallRating(false);
				return { success: false };
			}
			if (
				mysqlResponse.status != 200 &&
				mysqlResponse.data.request_status != "success"
			)
				throw new Error("No se ha podido actualizar");
			Object.assign(Payload.Evaluation, {
				client: this.user?._id,
				phone_number: `${this.ActiveCall?.call_number}`,
				agent: AuthModule.user?.id,
				uniqueid: this.ActiveCall.uniqueid,
				data: Payload?.data ? JSON.parse(Payload.data) : {},
				qualificationCallCode: mysqlResponse.data.id,
				callType: this.ActiveCall?.call_type,
				queue: this.ActiveCall?.queue,
			});

			const response: AxiosResponse = await omniChannelClient({
				method: "post",
				url: apiPetitions.createCallHistory,
				data: Payload.Evaluation,
			});
			if (response.status == 200 && response.data.value) {
				if (!env.DynamicTypification) {
					Payload.Evaluation.tipificacion = Payload.typificationName;
					Payload.Evaluation.subtipificacion = Payload.subtypificationName;
				}
				//Mandar aborrar la llamada si es single
				if (this.saveCallType == ISaveCallType.single) {
					const res = await this.deleteMysqlActiveCall(Payload.Evaluation);
					if (!res.success) {
						throw new Error("deleteMysqlActiveCall error");
					}
				}
				if (this.saveCallType != ISaveCallType.saveCall) {
					this.setUser(undefined);
					this.setScore(false);
					HistoryModule.setHistoryUser(undefined);
					this.setActiveCall({
						call_number: "",
						uniqueid: "",
						call_status: "Cola",
						resultado: 0,
						call_type: "",
						queue: "Desconocido",
					});
					this.setIsNewUser(true);
					this.updateActiveCall();
				}

				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: response.data.message,
					type: "success",
				});
				this.setLoadingCallRating(false);
				return { success: true };
			} else {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: alertMessages.ERROR_UPDATE,
					type: "error",
				});
				this.setLoadingCallRating(false);
				return { success: false };
			}
		} catch (error: any) {
			console.error(error.message);
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: alertMessages.ERROR_UPDATE,
				type: "error",
			});
			this.setLoadingCallRating(false);
			return { success: false };
		}
	}
	@Action
	async saveCall(callData: ISaveCall) {
		try {
			const response: AxiosResponse = await simpleCallCenterClient({
				method: "post",
				url: `controllers/guardarllamada.php`,
				data: Object.assign(callData, {
					extension: AuthModule.extension ?? "",
					agente: AuthModule.code ?? "",
				}),
			});

			if (response.data.resultado == "OK") {
				//Consultar registro del cliente
				this.updateActiveCall({ phone: response.data.telefono });
				console.log("REGISTRO ALMACENADO DE LLAMADA SALIENTE: ", response);
			}
		} catch (error: any) {
			console.log(Object.keys(error));
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: "Error al guardar la evaluación, guardarllamada.php",
				type: "warning",
			});
			console.error(error, "Send evaluation error, guardarllamada.php");
		}
	}
	@Action
	async sendMysqlEvaluation(
		Evaluation: IEvaluation
	): Promise<AxiosResponse<any, any> | undefined> {
		try {
			console.log("sendMysqlEvaluation");

			if (!env.DynamicTypification)
				validateTipification(
					Evaluation.tipificacion,
					Evaluation.subtipificacion
				);
			Evaluation.correo = env.DynamicUser
				? this.user?.dynamicData?.email?.toString() ?? ""
				: this.user?.email ?? "";
			Evaluation.direccion = env.DynamicUser
				? this.user?.dynamicData?.address?.toString() ?? ""
				: this.user?.address ?? "";
			Evaluation.nombre_completo = this.user?.customer?.full_name;
			Evaluation.numero_telefono = this.ActiveCall?.call_number;
			Evaluation.uniqueid = this.ActiveCall?.uniqueid;
			if (typeof Evaluation.data !== "string") {
				Evaluation.tipificacion = Evaluation.data?.typification;
				Evaluation.subtipificacion = Evaluation.data?.subtypification;
			}
			// Evaluation.subtipificacion = Evaluation.data["subtypification"];
			Evaluation.data = JSON.stringify(Evaluation.data);
			Evaluation.type = this.saveCallType;
			const mysqlResponse: AxiosResponse = await simpleCallCenterClient({
				method: "post",
				url: `controllers/saveCallEvaluation.php`,
				data: Object.assign(Evaluation, {
					agente: AuthModule.code ? AuthModule.code : "",
					extension: AuthModule.extension ? AuthModule.extension : "",
				}),
			});
			return mysqlResponse;
		} catch (error: any) {
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text:
					error.message.indexOf("timeout") > -1
						? "No se ha podido procesar su solicitud, intente en unos segundos"
						: "Error al guardar la evaluación",
				type: "warning",
			});
			console.error(error, "Send evaluation error");
		}
	}

	@Action
	async deleteMysqlActiveCall(
		Evaluation: IEvaluation
	): Promise<{ success: boolean }> {
		try {
			if (!this.ActiveCall?.uniqueid) return { success: false };
			console.log("deleteMysqlActiveCall");
			this.setLoadingCallDelete(true);
			Evaluation.uniqueid = this.ActiveCall?.uniqueid;
			Evaluation.type = this.saveCallType;
			Evaluation.nombre_completo = this.user?.customer?.full_name;
			Evaluation.numero_telefono = this.ActiveCall?.call_number;
			const response = await simpleCallCenterClient({
				method: "post",
				url: `controllers/clearCallEvaluation.php`,
				data: Object.assign(Evaluation, {
					agente: AuthModule.code ? AuthModule.code : "",
					extension: AuthModule.extension ? AuthModule.extension : "",
					validateEvaluation: env.needEvaluationToClearActiveCall,
					queue: this.ActiveCall?.queue,
				}),
			});
			const res = parseResponse(response);
			if (res.errorCode) {
				throw new Error(res.message);
			}
			this.setUser(undefined);
			this.setScore(false);
			HistoryModule.setHistoryUser(undefined);
			this.setActiveCall({
				call_number: "",
				uniqueid: "",
				call_status: "Cola",
				resultado: 0,
				call_type: "",
				queue: "",
			});
			this.setIsNewUser(true);
			this.updateActiveCall();
			this.setLoadingCallDelete(false);
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: res.message,
				type: "success",
			});
			return { success: true };
		} catch (error: unknown) {
			this.setLoadingCallDelete(false);
			console.group("Error al borrar llamada activa, deleteMysqlActiveCall");
			console.debug(error);
			console.groupEnd();

			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text:
					error instanceof Error ? error.message : alertMessages.ERROR_UPDATE,
				type: "error",
			});
			return { success: false };
		}
	}

	@Action
	async updateEvaluation(payload: any) {
		const mysqlResponse = await this.updateMysqlEvaluation(payload);
		if (mysqlResponse == undefined) return;
		if (
			mysqlResponse.status != 200 &&
			mysqlResponse.data.request_status != "success"
		)
			throw new Error("No se ha podido actualizar la calificación de llamada");
		const mongoResponse = await this.updateMongoEvaluation(payload);
		if (mongoResponse?.status == 200) {
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: "Se ha actualizado la calificación de llamada correctamente!!!",
				type: "success",
			});
			return true;
		} else {
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: "No se ha actualizado la calificación de llamada correctamente!!!",
				type: "error",
			});
			return false;
		}
	}

	@Action
	async updateMysqlEvaluation(
		evaluation: any
	): Promise<AxiosResponse<any, any> | undefined> {
		const mysqlResponse: AxiosResponse = await simpleCallCenterClient({
			method: "post",
			url: "controllers/updateClientCall.php",
			data: {
				id: TypificationModalModule.idSql?.toString(),
				dynamicData: JSON.stringify(evaluation),
			},
		});
		return mysqlResponse;
	}

	@Action
	async updateMongoEvaluation(
		evaluation: any
	): Promise<AxiosResponse<any, any> | undefined> {
		const mongoResponse: AxiosResponse = await omniChannelClient({
			method: "post",
			url: apiPetitions.updateCallHistory,
			data: { id: TypificationModalModule.idMongo, data: evaluation },
		});
		return mongoResponse;
	}

	@Action
	async getCallCenterClient(phone: string) {
		//VERIFICAR SI HAY REGISTRO EL USUARIO EN OMNICANAL
		const OmniResponse: AxiosResponse = await omniChannelClient({
			method: "get",
			url: env.DynamicUser
				? `client/ba/dynamicByNumber/503${phone}`
				: `client/ba/byNumber/503${phone}`,
		});
		if (OmniResponse.status == 200) {
			if (OmniResponse.data !== null && OmniResponse.data.data !== null) {
				this.setIsNewUser(false);
				const {
					email,
					address,
					company_name,
					customer,
					_id,
					dui_nit,
					dynamicData,
					identification,
					channels,
				} = OmniResponse.data.data as IUser;
				delete customer["name"];
				// console.log(customer, "customer enviado");
				this.setUser({
					email,
					address,
					company_name,
					customer,
					_id,
					dui_nit,
					dynamicData,
					identification,
					channels,
				});
			}
		}
	}
}
export const CallDataModule = getModule(CallData);
