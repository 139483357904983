import {
	Action,
	getModule,
	Module,
	Mutation,
	VuexModule,
} from "vuex-module-decorators";
import { store } from "@/store";
import { omniChannelClient } from "@/conection";
import { ITablePagination } from "@/types/components";
import { IndexType, IConfig } from "@/types/store";

export interface IApp {
	counter: number;
}

export interface IUserSettings {
	showSidebar: boolean;
}
type TypeAudios = "NEW_TICKET" | "NEW_MESSAGE" | "NEW_NOTIFICATION";
type AudioSrc = {
	[k in TypeAudios]: string;
};
type NotificationsElement = {
	[k in TypeAudios]?: HTMLAudioElement;
};
export const Audios: AudioSrc = {
	NEW_MESSAGE: "/audio/new_message.mp3",
	NEW_TICKET: "/audio/new_ticket.mp3",
	NEW_NOTIFICATION: "/audio/new_notification.wav",
};

@Module({ dynamic: true, store, name: "app" })
class App extends VuexModule implements IApp {
	counter: number;
	notificationsElement: NotificationsElement = {};
	dynamicUser = {};
	_configs: IConfig[] = [];

	get configs() {
		return this._configs;
	}
	_showTopNavbar = false;
	_pagination: IndexType<ITablePagination | undefined> = {};

	get pagination() {
		return this._pagination;
	}
	get userSettings(): IUserSettings {
		const showSidebar = localStorage.getItem("SHOW_SIDEBAR") === "true";
		return {
			showSidebar: showSidebar,
		};
	}
	get showNavbar() {
		return this._showTopNavbar;
	}
	/**
	 *
	 * @param payload Empty for toggle
	 */
	@Mutation
	setShowNavbar(payload?: boolean) {
		this._showTopNavbar =
			payload !== undefined ? payload : !this._showTopNavbar;
	}
	@Mutation
	setNotificationElement(el: HTMLAudioElement[]) {
		(Object.keys(Audios) as TypeAudios[]).forEach(
			(a, i) => (this.notificationsElement[a] = el[i])
		);
	}

	@Mutation
	setUserSettings(settings: IUserSettings) {
		localStorage.setItem("SHOW_SIDEBAR", settings.showSidebar.toString());
	}

	@Mutation
	setConfigs(payload: IConfig[]) {
		this._configs = payload;
	}

	@Action
	async getConfig(payload: { organization: string }) {
		const response = await omniChannelClient.get<IConfig[]>(
			`/organization/ba/config_dynamic/${payload.organization}`
		);
		this.setConfigs(response.data);
	}

	@Mutation
	setPagination(payload: IndexType<ITablePagination>) {
		Object.entries(payload).forEach(
			([key, pagination]) => (this._pagination[key] = pagination)
		);
	}
}

export const AppModule = getModule(App);
