<template>
	<div
		class="custom-control custom-checkbox"
		:class="[
			{ disabled: disabled },
			{ [`custom-checkbox-${type}`]: type },
			inlineClass,
		]"
	>
		<input
			class="custom-control-input"
			:name="name"
			:id="name"
			:class="inputClasses"
			type="checkbox"
			:disabled="disabled"
			:value="checked"
			:checked="checked"
			@change="onChange"
		/>
		<label :for="name" class="custom-control-label">
			<slot>
				<span v-if="inline">&nbsp;</span>
			</slot>
		</label>
	</div>
</template>
<script>
import { useField } from "vee-validate";
export default {
	name: "base-checkbox",
	emits: ["update:modelValue"],
	model: {
		prop: "modelValue",
	},
	props: {
		modelValue: {
			type: [Array, Boolean],
			description: "Whether checkbox is checked",
		},
		value: {
			type: String,
			default: "",
		},
		name: {
			type: String,
		},
		disabled: {
			type: Boolean,
			description: "Whether checkbox is disabled",
		},
		inline: {
			type: Boolean,
			description: "Whether checkbox is inline",
		},
		inputClasses: {
			type: [String, Object, Array],
			description: "Checkbox input classes",
		},
		type: {
			type: String,
			description: "Checkbox type (e.g info, danger etc)",
		},
	},
	data() {
		return {
			cbId: "",
			touched: false,
		};
	},
	computed: {
		model: {
			get() {
				return this.modelValue;
			},
			set(check) {
				if (!this.touched) {
					this.touched = true;
				}
				this.$emit("update:modelValue", check);
			},
		},
		inlineClass() {
			if (this.inline) {
				return `form-check-inline`;
			}
			return "";
		},
	},
	setup(props, { emit }) {
		const { checked, handleChange, meta } = useField(props.name, undefined, {
			type: "checkbox",
			checkedValue: true,
			uncheckedValue: false,
		});
		const onChange = e => {
			handleChange(!!e.target.value);
			emit("update:modelValue", !!e.target.checked);
		};
		return {
			checked,
			meta,
			onChange,
		};
	},
	created() {
		this.cbId = Math.random().toString(16).slice(2);
	},
};
</script>
