import {
	Module,
	Mutation,
	Action,
	VuexModule,
	getModule,
} from "vuex-module-decorators";
import { IRootState, store } from "@/store";
import { AxiosResponse } from "axios";
import { simpleCallCenterClient, omniChannelClient } from "@/conection/index";
import { Module as Mod } from "vuex";
import { AuthModule } from "./Auth";
import { AuthStatus } from "@/types/router";
import { AlertModule } from "./Alert";
import { CallDataModule } from "@/store/modules/CallData";
import { IPagination } from "@/callcenter/omni";
import { apiPetitions } from "@/util/utils";
import {
	formatHistoryData,
	formatDynamicHistoryData,
	getFields,
} from "@/util/data";
import { env } from "@/config";
import { outCallsKey } from "@/util/consts";
import { AppModule } from "./app";
import { IColumn } from "@/types/components";
export interface IHistory {
	historyUser: IHistoryUser | undefined;
	historyCall: IHistoryCall | undefined;

	Loading: boolean;
}
export interface IHistoryUser {
	registro: number;
	td: IBody[] | IOmniBody[];
	th: IColumn[];
	limit?: number;
	page?: number;
	totalDocs?: number;
	totalPages?: number;
}
export interface IHistoryCall {
	cantidad: number;
	td: IHistoryCallBody[];
	th: ITittle[];
	totalDocs: number;
	totalPages: number;
}
export interface IHistoryCallBody {
	destino: string;
	fecha: string;
	hora: string;
	origen: string;
	tiempoHablando: string;
	tipo: string;
}
export interface IBody {
	CodAgente: string;
	Comentario: string;
	Extension: string;
	Fecha: string;
	SubTipificación: string;
	Tipificación: string;
}
export interface IOmniBody extends Partial<IBody> {
	FechaHora: string;
	Agente: string;
	Code?: string;
	Tipo: string;
	Cliente?: string;
	Numero?: string;
	queue?: string;
	_id?: string;
}
export interface ITittle {
	text: string | undefined;
	value: string | undefined;
	width?: number;
}

@Module({ dynamic: true, namespaced: true, store, name: "history" })
export class History extends VuexModule implements IHistory {
	constructor(module: Mod<ThisType<IHistory>, IRootState>) {
		super(module);
	}
	historyUser: IHistoryUser | undefined;
	loading = false;
	callLoading = false;

	historyCall: IHistoryCall | undefined;
	get HistoryUser() {
		return this.historyUser;
	}
	get Loading() {
		return this.loading;
	}
	get CallLoading() {
		return this.callLoading;
	}
	get HistoryCall() {
		return this.historyCall;
	}
	@Mutation
	setLoading(loading: boolean) {
		this.loading = loading;
	}
	@Mutation
	setCallLoading(loading: boolean) {
		this.callLoading = loading;
	}
	@Mutation
	setHistoryUser(historyUser: IHistoryUser | undefined) {
		this.historyUser = historyUser;
	}
	@Mutation
	setHistoryCall(historyCall: IHistoryCall | undefined) {
		this.historyCall = historyCall;
	}
	@Action
	async updateUserHistory(page?: IPagination) {
		this.setLoading(true);
		if (AuthModule.status == AuthStatus.FULL_AUTHENTICATED) {
			try {
				console.log("page?.agentCode", page?.agentCode);
				const response: AxiosResponse = await omniChannelClient({
					method: "get",
					url: apiPetitions.callHistory,
					params: {
						number: page?.sendNumber
							? `${CallDataModule.activeCall.call_number}`
							: "",
						page: page ? page.page : 1,
						limit: page ? page.limit : 6,
						filter: page ? page.filter : "",
						fields: await getFields(),
						agentCode: page?.agentCode ?? "",
						today: page?.today ?? false,
						onlyOutCalls: page?.onlyOutCalls ?? false,
						outCallsKey: outCallsKey,
					},
				});
				if (response?.status === 200) {
					const resultado = response.data.docs;
					const { TD, th } = env.DynamicTypification
						? await formatDynamicHistoryData(
								resultado,
								AppModule.configs.find(c => c.name === "dynamic_user"),
								AppModule.configs.find(c => c.name === "dynamic_typification")
						  )
						: await formatHistoryData(
								resultado,
								AppModule.configs.find(c => c.name === "dynamic_user")
						  );

					this.setHistoryUser({
						registro: response.data?.registro,
						th: th,
						td: TD,
						totalDocs: response.data.totalDocs,
						totalPages: response.data.totalPages,
						page: response.data.page,
						limit: response.data.limit,
					});
				} else {
					AlertModule.SOCKET_PUSH_NOTIFICATION({
						text: `Error: ${response.statusText} ,Code: ${response.status}`,
						type: "warning",
					});
				}
			} catch (error) {
				console.error("error-->", error);
			}
		}
		this.setLoading(false);
	}
	@Action
	async updateMysqlUserHistory() {
		this.setLoading(true);
		if (AuthModule.status == AuthStatus.FULL_AUTHENTICATED) {
			try {
				const response: AxiosResponse = await simpleCallCenterClient({
					method: "post",
					url: "controllers/getClientHistory.php",
					data: {
						telefono: CallDataModule.ActiveCall.call_number,
					},
				});
				if (response?.status === 200) {
					this.setHistoryUser({
						registro: response.data?.registro,
						td: response.data?.body,
						th: response.data?.th,
					});
				} else {
					AlertModule.SOCKET_PUSH_NOTIFICATION({
						text: `Error: ${response.statusText} ,Code: ${response.status}`,
						type: "warning",
					});
				}
			} catch (error) {
				console.error(error, "error");
			}
		}
		this.setLoading(false);
	}
	@Action
	//funcion abstracta
	async updateCallHistory(page?: IPagination) {
		this.setCallLoading(true);
		if (AuthModule.status == AuthStatus.FULL_AUTHENTICATED) {
			try {
				const response: AxiosResponse = await simpleCallCenterClient({
					method: "post",
					url: "controllers/consultHistorialLLamadas.php",
					data: {
						extension: AuthModule.extension ?? "",
						idAgente: AuthModule.code ?? "",
						page: page ? page.page : 1,
						limit: page ? page.limit : 6,
						filter: page ? page.filter : "",
						type: page ? page.type : "",
					},
				});
				if (response?.status === 200) {
					this.setHistoryCall({
						cantidad: response.data?.cantidad,
						td: response.data?.docs,
						th: response.data?.th,
						totalDocs: response.data?.totalDocs,
						totalPages: response.data?.totalPages,
					});
				} else {
					AlertModule.SOCKET_PUSH_NOTIFICATION({
						text: `Error: ${response.statusText} ,Code: ${response.status}`,
						type: "warning",
					});
				}
			} catch (error) {
				console.error(error, "error");
			}
		}
		this.setCallLoading(false);
	}
}

export const HistoryModule = getModule(History);
